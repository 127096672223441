import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import MainHeading from "../../../components/menu/MainHeading";

const Userguide = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "auto" });
    }, []);

    return (
        <Box w={"full"} bgColor={"white"} px={5} py={4} rounded={"md"}>
            <Flex display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <MainHeading title={"利用規約"} />
            </Flex>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum nihil veritatis sit sunt optio odio quaerat
            delectus iusto ullam consequuntur impedit beatae doloremque voluptas, vel quam fugit possimus inventore,
            cumque enim perspiciatis culpa aut rerum. Quaerat dolorem ipsa molestiae veritatis quidem officiis, vero
            placeat repellat officia omnis temporibus itaque soluta aut amet optio veniam quis consequuntur rem totam
            iusto est. Maiores quisquam, harum facere perspiciatis non hic veniam laborum quas id? Velit molestiae nulla
            optio mollitia necessitatibus quisquam eum sit possimus earum nisi eaque nam, architecto sed provident,
            accusamus exercitationem id. Reiciendis aperiam cumque dignissimos at quaerat. Voluptatem, nobis. Minus
            quidem, labore, possimus repellendus neque nulla expedita dolor perferendis unde quasi, hic ullam esse
            laborum ab sapiente veritatis sequi incidunt odio. Quibusdam a ipsam, quidem beatae doloribus accusantium
            amet quas quae ut accusamus repellendus nihil sint! Hic maiores eum commodi quis at laboriosam quasi
            repellendus ratione a. Eveniet suscipit tenetur libero, consectetur cum, unde rem asperiores blanditiis eum
            aut est commodi nihil facere ut? Facere deleniti laborum inventore, et corporis dolorem alias vitae.
            Adipisci inventore reprehenderit cum suscipit nisi. Quae voluptatum, enim possimus, eos voluptates at est,
            maxime omnis aspernatur exercitationem ducimus consectetur. Voluptas nisi eveniet ad saepe odio temporibus.
        </Box>
    );
};

export default Userguide;
